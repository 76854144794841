import { createSlice } from "@reduxjs/toolkit";

export const alertListSlice = createSlice({
  name: "updateAlertId",
  initialState: "",
  reducers: {
    setAlertId: (state, actions) => {
      return actions.payload;
    },
  },
});

export const { setAlertId } = alertListSlice.actions;
export default alertListSlice.reducer;
