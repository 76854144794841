import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

const initialState = {};

export const checkGroupPlantSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setPlantStatus: (state, action) => {
      return action.payload;
    },
    resetStatus: (state) => {
      return {};
    },
  },
});

const selectSelf = (state) => state;
export const meterStatus = createDraftSafeSelector(
  [selectSelf], // Pass an array of selectors
  (state) => state.plantsStatus.status
);
export const meterStatusByName = createDraftSafeSelector(
  [selectSelf], // Pass an array of selectors
  (state) => state.plantsStatus.status_by_meter
);
export const last_updated_time = createDraftSafeSelector(
  [selectSelf], // Pass an array of selectors
  (state) => state.plantsStatus.latest_time
);

export const { setPlantStatus, resetStatus } = checkGroupPlantSlice.actions;

export default checkGroupPlantSlice.reducer;
