import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireCompany = () => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  // console.log(auth);

  return !auth?.company_name_pk && auth?.user ? (
    <Navigate to="/config/companies_list" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default RequireCompany;
