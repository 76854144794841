import React from "react";
import loaiding from "../../assets/images/loading-1.gif";
import "./Loading.css";
import { t } from "i18next";
import TextTransformer from "../../classes/TextTransformer";

const Loading = ({ msg = undefined }) => {
  return (
    <>
      <div className="loading1">
        <img src={loaiding} alt="" />
        {msg !== undefined ? (
          <h2>{msg}</h2>
        ) : (
          <h2>{TextTransformer.ucWord(t("loading"))}...</h2>
        )}
      </div>
    </>
  );
};

export default Loading;
