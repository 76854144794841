const TextTransformer = {
  ucWord: (value) => {
    try {
      return value.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
    } catch (error) {
      return false;
    }
  },
  ucLetterOfSentence: (value) => {
    try {
      return value.replace(
        /(^\s*|\.\s*)([a-z])/g,
        (match, p1, p2) => p1 + p2.toUpperCase()
      );
    } catch (error) {
      return false;
    }
  },
};

export default TextTransformer;
