// set baseURL in "public/index.html" file
// <link rel="manifest" href="%PUBLIC_URL%/manifest.json" id="manifest"url="https://backend.energylog.in" />

// export const apiBaseUrl = "https://backend.energylog.in";
const apiBaseUrl = document
  .querySelector('link[id="manifest"]')
  .getAttribute("backend_url");

export const config = {
  apiBaseUrl: apiBaseUrl,
};
