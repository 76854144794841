import MyAxios from "axios";
import { config } from "../../config/backendURLConfig";

export const baseURL = config.apiBaseUrl;

const apiURL = `${baseURL}/api/`;

export default MyAxios.create({
  baseURL: apiURL,
});
