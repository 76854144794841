import { createSlice } from "@reduxjs/toolkit";

export const fetchPlantsDetailsWidgetSlice = createSlice({
  name: "plantsDetailsWidget",
  initialState: {},
  reducers: {
    setPlantsWidgetData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetPlantsWidgetData: (state) => {
      return {};
    },
  },
});

export const { setPlantsWidgetData, resetPlantsWidgetData } =
  fetchPlantsDetailsWidgetSlice.actions;

export default fetchPlantsDetailsWidgetSlice.reducer;
