import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../features/login/loginSlice";
import checkGroupReducer from "../features/sidebar/checkGroupPlantSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import plantReducer from "../features/sidebar/sidebarListSlice";
import plantsDetailWidgetReducer from "../features/dashboard/plantDetailsWidgetOnDashboard/fetchPlantsDetailsWidgetSlice";
import dataLoggerStatusReducer from "../features/dashboard/plantDetailsWidgetOnDashboard/dataLoggerStatusSlice";
import infoBoxReducer from "../features/dashboard/infoBoxSlice";
import alertListReducer from "../features/dashboard/alert/alertListSlice";
import staticInfoOnDashboardReducer from "../features/dashboard/staticInfoOnDashboard/staticInfoOnDashboardSlice";

export const store = configureStore({
  reducer: {
    auth: loginReducer,
    plantsStatus: checkGroupReducer,
    dataLoggerOnDashboard: dashboardReducer,
    plants: plantReducer,
    plantsDetailWidget: plantsDetailWidgetReducer,
    dataLoggerStatus: dataLoggerStatusReducer,
    plantsInDashboard: infoBoxReducer,
    updateAlertId: alertListReducer,
    staticInfo: staticInfoOnDashboardReducer,
  },
});
