import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

export const dashboardSlice = createSlice({
  name: "dataLoggerOnDashboard",
  initialState,
  reducers: {
    setDataLoggerOnDashboard: (state, action) => {
      return action.payload;
    },
  },
});

export const { setDataLoggerOnDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
