import { createSlice } from "@reduxjs/toolkit";

export const infoBoxSlice = createSlice({
  name: "plantsInDashboard",
  initialState: [],
  reducers: {
    setPlants: (state, actions) => {
      return actions.payload;
    },
  },
});

export const { setPlants } = infoBoxSlice.actions;
export default infoBoxSlice.reducer;
