import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const staticInfoOnDashboardSlice = createSlice({
  name: "staticInfo",
  initialState,
  reducers: {
    setStaticInfo: (state, action) => {
      state[action.payload.company_id] = action.payload;
    },
    resetStaticInfo: (state) => {
      return {};
    },
  },
});

export const { setStaticInfo, resetStaticInfo } =
  staticInfoOnDashboardSlice.actions;

export default staticInfoOnDashboardSlice.reducer;
