import { useNavigate } from "react-router-dom";
import MyAxios from "../features/api/MyAxios";
import i18n from "../config/i18n";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyId, setAuthUser } from "../features/login/loginSlice";

const useRefreshToken = () => {
  const navigate = useNavigate();
  const rDispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const refresh = async () => {
    const token =
      document.cookie.match("(^|;)\\s*token\\s*=\\s*([^;]+)")?.pop() || "";

    try {
      const response = await MyAxios.get("/refresh", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      localStorage.setItem("app_language", response?.data?.user?.lang || "en");
      i18n.changeLanguage(response?.data?.user?.lang || "en");

      if (response.data.user.company_name_pk) {
        rDispatch(
          setAuthUser({
            user: response.data.user.user_name,
            role: response.data.user.role,
            company_name_pk: response.data.user.company_name_pk,
            all_companies: response.data.user.all_companies,
            token: response.data.user.token,
            lan: response?.data?.user?.lang,
          })
        );
      } else {
        rDispatch(
          setAuthUser({
            user: response.data.user.user_name,
            role: response.data.user.role,
            token: response.data.user.token,
            lan: response?.data?.user?.lang,
          })
        );
      }

      if (auth.company_name_pk === undefined) {
        const company_name_pk =
          document.cookie
            .match("(^|;)\\s*company_name_pk\\s*=\\s*([^;]+)")
            ?.pop() || "";

        rDispatch(setCompanyId(company_name_pk));
      }

      if (localStorage.getItem("trustDevice")) {
        document.cookie =
          "token=" + response.data.user.token + "; max-age=2592000; path=/;";
      } else {
        document.cookie =
          "token=" + response.data.user.token + "; max-age=0; path=/;";
      }

      return response.data.user.token;
    } catch (err) {
      if (err?.response?.status === 503) {
        navigate("/maintenance_mode");
      } else if (err?.response?.status === 401) {
        // if token unauthorized
        document.cookie = "token=; max-age=0; path=/;";
      } else {
        const current = new Date();

        document.cookie = "backend_error=" + current + "; max-age=900; path=/;";
        navigate("/backend_response");
      }
    }
  };
  return refresh;
};

export default useRefreshToken;
