import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const CheckToken = () => {
  const navigate = useNavigate();
  const token =
    document.cookie.match("(^|;)\\s*token\\s*=\\s*([^;]+)")?.pop() || "";

  useEffect(() => {
    if (token !== "" && navigator.onLine === true) {
      navigate("dashboard/consolidated", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return <Outlet />;
};

export default CheckToken;
