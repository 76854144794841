import { createSlice } from "@reduxjs/toolkit";

export const dataLoggerStatusSlice = createSlice({
  name: "dataLoggerStatus",
  initialState: {},
  reducers: {
    setDataLoggerStatus: (state, action) => {
      return { ...action.payload };
    },
    resetDataLoggerStatus: (state) => {
      return undefined;
    },
  },
});

export const { setDataLoggerStatus, resetDataLoggerStatus } =
  dataLoggerStatusSlice.actions;

export default dataLoggerStatusSlice.reducer;
