import { useRef, useState, useEffect, memo } from "react";
import "./login.css";
// import loginlogo from "./loginlogo.png";
import userimg from "./user.png";
import { useNavigate, useLocation } from "react-router-dom";
import MyAxios from "../api/MyAxios";
import i18n from "../../config/i18n";
import { changeLanguage, t } from "i18next";
import TextTransformer from "../../classes/TextTransformer";
import { useDispatch, useSelector } from "react-redux";
import { setAuthUser, resetAuth } from "./loginSlice";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rDispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const from = location.state?.from?.pathname || "/dashboard/consolidated";

  //    console.log(from);

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState();
  const [trustDevice, setTrustDevice] = useState();

  useEffect(() => {
    userRef.current.focus();
    if (auth.user) {
      rDispatch(resetAuth());
    }
    setTrustDevice(localStorage.getItem("trustDevice"));
    i18n.changeLanguage(localStorage.getItem("app_language") || "en");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const trustdevice = (e) => {
    if (e.target.checked) {
      localStorage.setItem("trustDevice", e.target.checked);
    } else {
      localStorage.removeItem("trustDevice");
    }
    setTrustDevice(localStorage.getItem("trustDevice"));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await MyAxios.post(
        "/login",
        JSON.stringify({ username: user, password: pwd }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      // console.log(response?.data.user);

      const role = response?.data?.user?.role;
      const company_name_pk = response?.data?.user?.company_name_pk;
      const all_companies = response?.data?.user?.all_companies;
      const token = response?.data?.user?.token;
      const lan = response?.data?.user?.lang;
      localStorage.setItem("app_language", lan || "en");
      i18n.changeLanguage(lan || "en");
      changeLanguage(lan || "en");

      trustDevice
        ? (document.cookie = "token=" + token + "; max-age=2592000; path=/;")
        : (document.cookie = "token=; max-age=0; path=/;");

      rDispatch(
        setAuthUser({ user, role, company_name_pk, token, all_companies })
      );

      setUser("");
      setPwd("");
      // console.log(from);

      if (role === "Master") {
        navigate("/config/companies_list", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg(t("No Server Response"));
      } else if (err.response?.status === 400) {
        setErrMsg(t("Missing_u_p"));
      } else if (err.response?.status === 401) {
        setErrMsg(t("incorrect_credentials"));
      } else if (err?.response?.status === 503) {
        navigate("/maintenance_mode");
      } else {
        setErrMsg("Login Failed!");
        navigate("/backend_response");
      }

      console.log(err?.response?.data);
      // console.log(err?.response?.data?.m_pass_suffix);

      errRef.current.focus();
    }
  };

  return (
    <>
      {/* <div className="container-fluid"> */}
      <div className="logincont">
        <div className="row">
          <div className="col-lg-8 col-md-8 "></div>

          <div className="col-lg-4 col-md-4 third_col">
            <form id="log" onSubmit={handleSubmit}>
              <img
                src={
                  window.location.origin +
                  "/image/login/" +
                  process.env.REACT_APP_login_brand_image
                }
                alt="Brand logo not found"
                className="logo"
              />
              <img
                src={userimg}
                alt="user.png"
                className="rounded-circle img-fluid mx-auto d-block"
              />
              <div className="cont">
                <div className="form-group">
                  <label htmlFor="user_name">
                    {TextTransformer.ucLetterOfSentence(t("username"))}
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    id="user_name"
                    value={user}
                    ref={userRef}
                    className="form-control inputbox"
                    autoComplete="off"
                    required
                    onChange={(e) => setUser(e.target.value)}
                  />
                  <small
                    className="form-text text-danger bg-warning"
                    ref={errRef}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="password">
                    {TextTransformer.ucLetterOfSentence(t("password"))}
                  </label>
                  <input
                    type="password"
                    className="form-control inputbox"
                    name="password"
                    id="password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    required
                  />
                </div>
                <div className="">
                  <label htmlFor="remember">
                    <input
                      type="checkbox"
                      name="remember"
                      id="remember"
                      checked={trustDevice ? true : false}
                      onChange={(e) => trustdevice(e)}
                    />{" "}
                    {TextTransformer.ucLetterOfSentence(t("trust this device"))}
                  </label>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  {TextTransformer.ucLetterOfSentence(t("login"))}
                </button>
                {/* <div className="checkbox">
                  <Link to="/reset-password">Forget Password</Link>
                </div> */}
              </div>
            </form>
          </div>
        </div>
        {/* {{-- row --}} */}
        {/* </div>  {{-- container --}} */}

        <footer className="login-footer">
          {process.env.REACT_APP_footer_copyright_show === true && (
            <strong>
              Copyright &copy; 2014-{new Date().getFullYear()}{" "}
              <a href="http://ecoenergies.co.in">Eco Energies</a>. All rights
              reserved.
            </strong>
          )}
          &nbsp;
          <div className="d-none d-sm-inline-block pull-right">
            <b>Version</b> 3.0
          </div>
        </footer>
      </div>
    </>
  );
};

export default memo(Login);
