import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const sidebarListSlicer = createSlice({
  name: "plants",
  initialState,
  reducers: {
    setAll: (state, action) => {
      // console.log(action.payload);
      return action.payload;
    },
    setCheckedClient: (state, action) => {
      return { ...state, checked_client: action.payload };
    },
    resetPlants: (state) => {
      return {};
    },
  },
});

export const { setAll, setCheckedClient, resetPlants } =
  sidebarListSlicer.actions;
export default sidebarListSlicer.reducer;
